





































import { defineComponent, ref } from '@vue/composition-api';
import SubmissionVote from './SubmissionVote.vue';

export default defineComponent({
  name: 'ExpandedContestSubmission',

  components: {
    SubmissionVote,
  },

  props: {
    pictureUrl: {
      type: String,
      default: () => 'https://picsum.photos/id/654/1600/900',
    },
    contestantId: {
      type: String,
    },
    participantId: {
      type: String,
    },
    vote: {
      type: Boolean,
      default: () => false,
    },
  },

  setup() {
    const dialog = ref(false);

    return {
      dialog,
    };
  },
});
