








































































































import {
  defineComponent, onUnmounted, onMounted, ref,
} from '@vue/composition-api';
import ContestSubmission from '@/components/molecules/ContestSubmission.vue';
import ContestSubmissionForm from '@/components/molecules/ContestSubmissionForm.vue';
import LoaderSpin from '@/components/atoms/LoaderSpin.vue';
import { useGetContestSubmissionQuery, GetContestSubmissionQuery, useGetParticipantVotingDetailsQuery } from '@/generated/graphql';
import { useResult } from '@vue/apollo-composable';
import authComposition from '@/composable/authHandler';

export default defineComponent({
  name: 'ContestGallery',
  components: {
    LoaderSpin,
    ContestSubmission,
    ContestSubmissionForm,
  },
  setup(_, { root }) {
    const mode = ref(process.env.NODE_ENV);

    const {
      login, isAuthenticated, role, auth0_id,
    } = authComposition(root);
    const {
      result, loading, error, fetchMore,
    } = useGetContestSubmissionQuery({ limit: 6, offset: 0 }, {
      notifyOnNetworkStatusChange: true,
    });

    // Refresh page bug
    // const auth0_id = ref(root.$auth.user?.sub);
    const {
      result: result1, refetch,
    } = useGetParticipantVotingDetailsQuery({ auth0_id: auth0_id.value });
    const contestSubmissions = useResult(result, [], (data) => data.contest);
    const participantDetails = useResult(result1, [], (data) => {
      const watcher = setInterval(() => {
        if (isAuthenticated) {
          refetch({ auth0_id: auth0_id.value });
          clearInterval(watcher);
        }
      }, 1000);
      if (!data.participants.length) {
        // Public User
        return {
          id: '0',
          submission: false,
          vote: false,
        };
      }
      return data.participants[0];
    });

    function loadMore() {
      fetchMore({
        variables: {
          offset: result.value.contest.length,
        },
        updateQuery: (previousResult: GetContestSubmissionQuery, { fetchMoreResult }: any) => {
          // No new feed posts
          console.log(previousResult, fetchMoreResult);
          if (!fetchMoreResult) return previousResult;

          // Concat previous feed with new feed posts
          result.value.contest = [
            ...previousResult.contest,
            ...fetchMoreResult.contest,
          ];
          const { contest } = result.value;
          return {
            ...previousResult,
            contest,
          };
        },
      });
    }

    function scrollBehavior(e: any) {
      if ((window.innerHeight + window.scrollY + 5) >= document.body.offsetHeight && !loading.value) {
        console.log('Loading More');
        loadMore();
      }
    }
    onMounted(() => {
      window.addEventListener('scroll', scrollBehavior);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', scrollBehavior);
    });

    const showButton = ref(false);
    function onScroll(e: Event) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || (e.target as Element).scrollTop || 0;
      showButton.value = top > 20;
    }

    function toTop() {
      root.$vuetify.goTo(0);
    }

    const message = ref('If you are certain that you have logged in. Please click me!');

    function help() {
      refetch({ auth0_id: auth0_id.value });
    }

    return {
      help,
      message,
      loading,
      error,
      loadMore,
      contestSubmissions,
      participantDetails,
      login,
      isAuthenticated,
      role,
      showButton,
      onScroll,
      toTop,
      mode,
    };
  },
});
